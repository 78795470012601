import React from 'react';
import { Link } from 'react-router-dom';

export const albums = [
    {
        title: 'City on a Hill',
        cover: './portfolio/COAH/steeple-bos.jpg',
        images: require.context('../../public/portfolio/COAH', false, /\.(png|jpe?g|svg|JPG)$/)
    },
    {
        title: 'Home',
        cover: './portfolio/Home/watercolor-frames.jpg',
        images: require.context('../../public/portfolio/Home', false, /\.(png|jpe?g|svg|JPG)$/)
    },
    {
        title: 'Historic Philadelphia',
        cover: './portfolio/HP/courthouse-garden.JPG',
        images: require.context('../../public/portfolio/HP', false, /\.(png|jpe?g|svg|JPG)$/)
    },
    {
        title: 'Late Summer in Chicago',
        cover: './portfolio/LSIC/wabash-goldenhour.JPG',
        images: require.context('../../public/portfolio/LSIC', false, /\.(png|jpe?g|svg|JPG)$/)
    },
    {
        title: 'In the Trees',
        cover: './portfolio/ITT/branch-portal.jpg',
        images: require.context('../../public/portfolio/ITT', false, /\.(png|jpe?g|svg|JPG)$/)
    },
    {
        title: 'Sweet Swanzey',
        cover: './portfolio/SS/lake-morning.JPG',
        images: require.context('../../public/portfolio/SS', false, /\.(png|jpe?g|svg|JPG)$/)
    },
];

const TempHomePage = () => {
    return (
        <div className="flex flex-col items-center pt-20">
            {albums.map((album, index) => (
                <div
                    key={index}
                    className="relative w-full max-w-4xl mx-auto mb-5 group"
                >
                    <Link to={album.title.replace(/\s+/g, '-').toLowerCase()}>
                        <img
                            src={album.cover}
                            alt={album.title}
                            className="w-full max-w-[80dvw] h-[50vh] mx-auto object-cover"
                        />
                        <div
                            className="absolute inset-0 bg-black bg-opacity-60 text-white flex items-center justify-center text-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 h-[50vh] max-w-[80dvw] mx-auto"
                        >
                            {album.title}
                        </div>
                    </Link>
                    <Link
                        to={`/${album.title.replace(/\s+/g, '-').toLowerCase()}`}
                        className="block text-center mt-2 text-blue-500"
                    >
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default TempHomePage;