import React from 'react';
import { useParams } from 'react-router-dom';
import { albums } from './TempHomePage';

const AlbumImagesPage = () => {
    const { albumName } = useParams();

    if (!albumName) {
        return <div>Album name not found</div>;
    }

    const album = albums.find((a) =>
        a.title.replace(/\s+/g, '-').toLowerCase() === albumName.toLowerCase()
    );

    if (!album) {
        return <div>Album not found</div>;
    }

    const imageFiles = album.images.keys().map((key) => album.images(key));

    // Split images into two arrays for alternating
    const half = Math.ceil(imageFiles.length / 2);
    const images1 = imageFiles.slice(0, half);
    const images2 = imageFiles.slice(half);

    return (
        <div className="flex flex-col md:flex-row gap-8 pt-20 mx-8">
            <div className="flex-1 flex flex-col gap-4">
                {images1.map((image, index) => (
                    <div key={index} className="w-full mb-4">
                        <img
                            src={image}
                            alt={`Image ${index + 1}`}
                            className="w-full h-auto object-cover"
                        />
                    </div>
                ))}
            </div>
            <div className="flex-1 flex flex-col gap-4">
                {images2.map((image, index) => (
                    <div key={index} className="w-full mb-4">
                        <img
                            src={image}
                            alt={`Image ${index + 1 + half}`}
                            className="w-full h-auto object-cover"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AlbumImagesPage;
