import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import linkedin from '../img/icons/linkedin.png';
import instagram from '../img/icons/instagram.png';

const NavBar = () => {
    const [hamburgerClicked, setHamburgerClicked] = useState(false);

    const handleHamburgerClick = () => {
        setHamburgerClicked(!hamburgerClicked);
    };

    return (
        <>
            <nav className='flex justify-between items-center p-4 fixed top-[-1] w-full h-14 bg-background z-50'>
                {/* Social Media Links */}
                <div className="flex gap-2 items-center h-full">
                    <a href="https://www.linkedin.com/in/rebecca-abreu-0aa718328/" target="_blank" rel="noreferrer" className="text-black hover:text-gray-600">
                        <img src={linkedin} alt="LinkedIn" className="w-8 h-8"/>
                    </a>
                    <a href="https://www.instagram.com/bwilson.photos/" target="_blank" rel="noreferrer" className="text-black hover:text-gray-600">
                        <img src={instagram} alt="Instagram" className="w-6 h-6 md:w-8 md:h-8"/>
                    </a>
                    {/* <a className="text-black hover:text-gray-600">FB</a>
                    <a className="text-black hover:text-gray-600">YT</a> */}
                </div>
                {/* Title */}
                <Link to="/" className="text-lg font-bold absolute left-1/2 transform -translate-x-1/2">Becca Abreu</Link>
                {/* Hamburger Menu Button */}
                {/* <button 
                    className="relative inline-block w-[44px] h-[45px] z-40" 
                    onClick={handleHamburgerClick}
                >
                    <span
                        className={`absolute left-[0] -mt-[12px] h-[6px] bg-[#000] block transition-all duration-200 ${hamburgerClicked ? ' w-[10px] -mt-[6px] left-[10px]' : 'w-[30px]'}`}
                    />
                    <span
                        className={`absolute left-[0] h-[6px] bg-[#000] block transition-all duration-200 ${hamburgerClicked ? ' w-[20px] left-[5px]' : 'w-[30px]'}`}
                    />
                    <span
                        className={`absolute left-[0] mt-[12px] w-[30px] h-[6px] bg-[#000] block transition-all duration-200 ${hamburgerClicked ? ' mt-[6px]' : ''}`}
                    />
                </button>
                {hamburgerClicked && <SideBar />} */}
            </nav>
        </>
    );
};

export default NavBar;