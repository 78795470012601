import React from 'react';

const Footer = () => {
    return (
        <footer className="w-full py-4">
            <div className="container mx-auto text-center">
                <p className="text-gray-600">Rebecca Abreu, 2024</p>
            </div>
        </footer>
    );
};

export default Footer;
