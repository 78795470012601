import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './components/NavBar';
import Landing from './components/Landing';
import Palettes from './components/Palettes';
import Community from './components/Community';
import TempHomePage from './components/TempHomePage';
import AlbumImagesPage from './components/AlbumImagesPage';
import Footer from './components/Footer';

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <NavBar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<TempHomePage />} />
            <Route path="/:albumName" element={<AlbumImagesPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
