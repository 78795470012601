import React from 'react';
import Book from './Book';
import woods_scenic from '../img/community/woods_scenic.png';

function Community() {
    return (
        <div className="relative w-full h-screen overflow-hidden">
            <img 
                src={woods_scenic} 
                alt="Scenic woods background" 
                className="absolute w-full h-full object-cover"
            />
            <div className="flex flex-col absolute inset-0 items-center justify-center">
                <h1 className="text-white">Album 01</h1>
                <h1 className="text-white mb-4">Friendship & Community</h1>
                <Book />
            </div>
        </div>
    );
}

export default Community;