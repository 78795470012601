import React from 'react';

const SideBar = () => {
    return (
        <div className="right-0 top-0 h-[100%] w-1/4 fixed bg-white z-30">
            <div className="flex flex-col mt-32">
                
            </div>
        </div>
    );
};

export default SideBar;
